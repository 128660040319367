import * as React from 'react'
import { Cell } from './Cell'
import { Shape } from '../shapes'

export const Pile = ({
  cellsFilled,
  size,
}: {
  cellsFilled: Shape
  size: number
}): JSX.Element => {
  return (
    <div>
      {cellsFilled.map(cell => (
        <Cell
          className={'pile'}
          key={[cell.left, cell.top].join(',')}
          top={cell.top}
          left={cell.left}
          color={cell.color}
          size={size}
        />
      ))}
    </div>
  )
}
