import { range } from 'lodash'
import { Shape, Coordinate } from './shapes'
import * as shapes from './shapes'
import { matrix, multiply } from 'mathjs'

export const getLefts = (shape: Shape): number[] => shape.map(cell => cell.left)
export const getTops = (shape: Shape): number[] => shape.map(cell => cell.top)
export const sizeOfDimension = (dimension: number[]) =>
  Math.max(...dimension) - Math.min(...dimension) + 1
export const shapeWidth = (shape: Shape) => sizeOfDimension(getLefts(shape))
export const shapeHeight = (shape: Shape) => sizeOfDimension(getTops(shape))

// Interrogate Shape
export const shapeAsColumns = (shape: Shape): Shape[] => {
  const columns = range(
    Math.min(...getLefts(shape)),
    Math.max(...getLefts(shape)) + 1,
  ).map(column => {
    return shape.filter(cell => cell.left == column)
  })
  return columns
}

export const shapeAsRows = (shape: Shape): Shape[] => {
  const rows = range(
    Math.min(...getTops(shape)),
    Math.max(...getTops(shape)) + 1,
  ).map(row => {
    return shape.filter(cell => cell.top == row)
  })
  return rows
}

export const getSide = (
  side: 'top' | 'bottom' | 'left' | 'right',
  shape: Shape,
): Shape => {
  const shapeColumns = shapeAsColumns(shape)
  const shapeRows = shapeAsRows(shape)

  return shapeWidth(shape) === 0
    ? shape
    : side === 'top' || side === 'bottom'
    ? range(0, shapeWidth(shape)).flatMap(column => {
        return shapeColumns[column].filter(cell => {
          const tops = getTops(shapeColumns[column])
          return side === 'bottom'
            ? cell.top == Math.max(...tops)
            : cell.top == Math.min(...tops)
        })
      })
    : range(0, shapeHeight(shape)).flatMap(row => {
        return shapeRows[row].filter(cell => {
          const lefts = getLefts(shapeRows[row])
          return side === 'right'
            ? cell.left == Math.max(...lefts)
            : cell.left == Math.min(...lefts)
        })
      })
}

export const translate = (shape: Shape, position: Coordinate) => {
  return shape.map(cell => {
    return {
      left: cell.left + position.left,
      top: cell.top + position.top,
      color: cell.color,
    }
  })
}

function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// TODO: make this get all the shapes in shapes
export const randomShape = () => {
  const shapeArray: Shape[] = [
    shapes.ida,
    shapes.jemmima,
    shapes.lars,
    shapes.ole,
    shapes.sigve,
    shapes.tuva,
    shapes.zacharias,
  ]
  return shapeArray[randomIntFromInterval(0, shapeArray.length - 1)]
}

function positiveArray(array: number[], translation: number) {
  return [array[0], array[1] + translation]
}

export const rotate = (currentShape: Shape): Shape => {
  const shapeMaxX: number = Math.max(...getLefts(currentShape))
  const shapeMatrix: number[][] = currentShape.map(cell => [
    cell.left,
    cell.top,
  ])
  const rotationMatrix = matrix([
    [0, -1],
    [1, 0],
  ])
  const rotatedMatrix: number[][] = multiply(shapeMatrix, rotationMatrix)
  const rotatedArray: number[][] = Object.values(rotatedMatrix.valueOf())
  const positiveRotatedArray = rotatedArray.map(element =>
    positiveArray(element, shapeMaxX),
  )
  return positiveRotatedArray.map(cell => {
    return {
      left: cell[0],
      top: cell[1],
      color: currentShape[0].color,
    }
  })
}

export const shapeAdjacentCells = (
  shape: Shape,
  side: 'left' | 'right' | 'top',
): Shape => {
  return shape.map(
    (cell: shapes.ColoredCell): shapes.ColoredCell => {
      return side === 'left'
        ? { color: cell.color, left: cell.left - 1, top: cell.top }
        : side === 'right'
        ? { color: cell.color, left: cell.left + 1, top: cell.top }
        : { color: cell.color, left: cell.left, top: cell.top }
    },
  )
}
