import * as React from "react";
import * as shapes from "./shapes";
import { Grid } from "./components/Grid";
import { ActiveShape } from "./components/ActiveShape";
import { Pile } from "./components/Pile";
import "./App.css";
import {
  hitPile,
  removeWinningLines,
  pileDrop,
  winningLines,
  loseGame,
  notHitPileSides,
} from "./pileUtils";
import {
  gameWidth,
  gameHeight,
  backgroundColor,
  gameCellSize,
} from "./gameConfig";
import { gridClamp } from "./gridUtils";
import { randomShape, translate, shapeHeight, rotate } from "./shapeUtils";
import { Coordinate } from "./shapes";
import { Control } from "./components/Control";
import { useState, useRef, useEffect } from "react";

export const App = () => {
  const [shapePosition, setShapePosition] = useState<Coordinate>({
    left: Math.round(gameWidth / 2 - 2),
    top: 0,
  });
  const [pile, setPile] = useState<shapes.Shape>([]);
  const [gridColor, setGridColor] = useState<string>(backgroundColor);
  const [currentShape, setCurrentShape] = useState<shapes.Shape>(randomShape());
  const [shapeID, setShapeID] = useState<number>(Math.random());
  const [score, setScore] = useState<number>(0);

  const gridRef: React.RefObject<HTMLDivElement> = useRef(null);
  let gravity: number = -1;

  useEffect(() => {
    gridRef.current.focus();

    gravity = window.setInterval(() => handleMoveShape("down"), 1000);
    return () => {
      clearInterval(gravity);
    };
  });

  const endGame = () => {
    setGridColor("black");
    clearInterval(gravity);
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    let direction;
    if (event.key === "ArrowDown") {
      direction = "down";
    } else if (event.key === "ArrowRight") {
      direction = "right";
    } else if (event.key === "ArrowLeft") {
      direction = "left";
    } else if (event.key === "ArrowUp") {
      direction = "up";
    } else if (event.key === " ") {
      direction = "drop";
    } else if (event.key === "Enter") {
      location.reload();
    }

    if (direction) {
      event.preventDefault();
      handleMoveShape(direction);
    }
  };

  const handleMoveShape = (
    direction: "up" | "down" | "left" | "right" | "drop"
  ) => {
    let nextShapePosition = shapePosition;
    let nextShape = currentShape;
    let scoreIncrement = 0;

    const addShapeToPile = (nextShapePosition, nextShape) => {
      let nextPile = pile.concat(translate(nextShape, nextShapePosition));
      const winLines = winningLines(gameWidth, nextPile);
      nextPile = removeWinningLines(nextPile, winLines);
      winLines.forEach((line) => {
        nextPile = pileDrop(nextPile, line);
      });
      scoreIncrement =
        nextPile.length === 0
          ? scoreIncrement + 1000
          : scoreIncrement +
            winLines.length * winLines.length * winLines.length;

      if (loseGame(gameHeight, nextPile)) {
        endGame();
      } else {
        setPile(nextPile);
        setScore(score + scoreIncrement);
        setShapePosition({ left: Math.round(gameWidth / 2 - 2), top: 0 });
        setCurrentShape(randomShape());
        setShapeID(Math.random());
      }
    };

    if (direction === "down") {
      nextShapePosition = {
        left: shapePosition.left,
        top: shapePosition.top + 1,
      };
    } else if (
      direction === "right" &&
      notHitPileSides(
        { left: shapePosition.left + 1, top: shapePosition.top },
        nextShape,
        pile
      )
    ) {
      nextShapePosition = {
        left: shapePosition.left + 1,
        top: shapePosition.top,
      };
    } else if (
      direction === "left" &&
      notHitPileSides(
        { left: shapePosition.left - 1, top: shapePosition.top },
        nextShape,
        pile
      )
    ) {
      nextShapePosition = {
        left: shapePosition.left - 1,
        top: shapePosition.top,
      };
    } else if (
      direction === "up" &&
      notHitPileSides(nextShapePosition, rotate(currentShape), pile)
    ) {
      nextShape = rotate(currentShape);
    }

    if (hitPile(gameHeight, nextShape, nextShapePosition, pile)) {
      addShapeToPile(
        { left: nextShapePosition.left, top: nextShapePosition.top - 1 },
        nextShape
      );
    } else {
      nextShapePosition = gridClamp(
        nextShapePosition,
        nextShape,
        gameWidth,
        gameHeight
      );
      setShapePosition(nextShapePosition);
      setCurrentShape(nextShape);
    }

    if (direction === "drop") {
      if (pile.length === 0) {
        nextShapePosition = {
          left: shapePosition.left,
          top: gameHeight - shapeHeight(currentShape),
        };
      } else {
        const dropPosition = (pile, shapePosition, currentShape) => {
          if (
            hitPile(
              gameHeight,
              currentShape,
              { left: shapePosition.left, top: shapePosition.top + 1 },
              pile
            ) === true
          ) {
            nextShapePosition = {
              left: shapePosition.left,
              top: shapePosition.top,
            };
          } else {
            dropPosition(
              pile,
              { left: shapePosition.left, top: shapePosition.top + 1 },
              currentShape
            );
          }
        };
        dropPosition(pile, shapePosition, currentShape);
      }
      addShapeToPile(nextShapePosition, nextShape);
    }
  };

  const controls = [
    { action: "newGame", symbol: "✨" },
    { action: "up", symbol: "⤵" },
    { action: "left", symbol: "←" },
    { action: "right", symbol: "→" },
    { action: "down", symbol: " ↓" },
    { action: "drop", symbol: "🎢" },
  ];

  const controlButtons = controls.map((control) => {
    return (
      <Control
        key={`${control.action}`}
        option={control.action}
        symbol={control.symbol}
        handleButtonClick={handleMoveShape}
      />
    );
  });

  return (
    <>
      <div
        ref={gridRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        className="outer"
        style={{
          display: "grid",
          gridTemplate: `${gameCellSize()}px ${
            gameCellSize() * (gameHeight - 1)
          }px / ${gameCellSize() * gameWidth}px ${gameCellSize() * 2}px`,
        }}
      >
        <div
          className="game"
          style={{
            gridColumn: 1,
            gridRow: 1 / 2,
            position: "relative",
          }}
        >
          <Grid
            numberOfColumns={gameWidth}
            numberOfRows={gameHeight}
            backgroundColor={gridColor}
            cellSize={gameCellSize()}
          />
          <ActiveShape
            key={shapeID}
            color={currentShape[0].color}
            shape={currentShape}
            position={shapePosition}
            size={gameCellSize()}
          />
          <Pile cellsFilled={pile} size={gameCellSize()} />
        </div>
        <div
          className="score"
          style={{
            gridColumn: 2,
            gridRow: 1,
            padding: 0,
            fontSize: gameCellSize() * 0.8,
            textAlign: "center",
          }}
        >
          {score}
        </div>
        <div className="controlButtons" style={{ gridColumn: 2, gridRow: 2 }}>
          {controlButtons}
        </div>
      </div>
    </>
  );
};
