import * as React from 'react'

export const Cell = ({
  color,
  top,
  left,
  size,
  className,
}: {
  color: string
  top: number
  left: number
  size: number
  className: string
}): JSX.Element => {
  return (
    <>
      <div
        className={className}
        style={{
          height: size,
          width: size,
          backgroundColor: color,
          position: 'absolute',
          top: top * size,
          left: left * size,
        }}
      ></div>
    </>
  )
}
