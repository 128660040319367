import * as React from 'react'
import { Cell } from './Cell'
import { Shape, Coordinate } from '../shapes'

export const ActiveShape = ({
  color,
  shape,
  position,
  size,
}: {
  color: string
  shape: Shape
  position: Coordinate
  size: number
}): JSX.Element => {
  return (
    <>
      <div>
        {shape.map((shapeCell, i) => (
          <Cell
            className={'animate'}
            key={i}
            top={shapeCell.top + position.top}
            left={shapeCell.left + position.left}
            color={color}
            size={size}
          />
        ))}
      </div>
    </>
  )
}
