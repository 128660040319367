export type Coordinate = { left: number; top: number }
export type ColoredCell = { left: number; top: number; color: string }
export type Shape = ColoredCell[]

export const ole: Shape = [
  { left: 0, top: 0, color: 'green' },
  { left: 0, top: 1, color: 'green' },
  { left: 1, top: 0, color: 'green' },
  { left: 1, top: 1, color: 'green' },
]

export const ida: Shape = [
  { left: 0, top: 0, color: 'red' },
  { left: 0, top: 1, color: 'red' },
  { left: 0, top: 2, color: 'red' },
  { left: 0, top: 3, color: 'red' },
]

export const tuva: Shape = [
  { left: 0, top: 0, color: 'blue' },
  { left: 1, top: 0, color: 'blue' },
  { left: 1, top: 1, color: 'blue' },
  { left: 2, top: 0, color: 'blue' },
]

export const jemmima: Shape = [
  { left: 0, top: 0, color: 'magenta' },
  { left: 1, top: 0, color: 'magenta' },
  { left: 2, top: 0, color: 'magenta' },
  { left: 2, top: 1, color: 'magenta' },
]

export const lars: Shape = [
  { left: 0, top: 0, color: 'yellow' },
  { left: 0, top: 1, color: 'yellow' },
  { left: 1, top: 0, color: 'yellow' },
  { left: 2, top: 0, color: 'yellow' },
]

export const sigve: Shape = [
  { left: 0, top: 1, color: 'pink' },
  { left: 1, top: 0, color: 'pink' },
  { left: 2, top: 0, color: 'pink' },
  { left: 1, top: 1, color: 'pink' },
]

export const zacharias: Shape = [
  { left: 0, top: 0, color: 'orange' },
  { left: 1, top: 0, color: 'orange' },
  { left: 2, top: 1, color: 'orange' },
  { left: 1, top: 1, color: 'orange' },
]

export const bjørge: Shape = [
  { left: 0, top: 0, color: 'salmon' },
  { left: 0, top: 1, color: 'salmon' },
  { left: 0, top: 2, color: 'salmon' },
  { left: 0, top: 3, color: 'salmon' },
  { left: 1, top: 1, color: 'salmon' },
  { left: 1, top: 3, color: 'salmon' },
  { left: 2, top: 1, color: 'salmon' },
  { left: 2, top: 2, color: 'salmon' },
  { left: 2, top: 3, color: 'salmon' },
]

export const victoria: Shape = [
  { left: 0, top: 0, color: 'purple' },
  { left: 1, top: 1, color: 'purple' },
  { left: 2, top: 2, color: 'purple' },
  { left: 3, top: 1, color: 'purple' },
  { left: 4, top: 0, color: 'purple' },
]

export const chris: Shape = [
  { left: 1, top: 0, color: 'lime' },
  { left: 0, top: 1, color: 'lime' },
  { left: 2, top: 1, color: 'lime' },
  { left: 0, top: 2, color: 'lime' },
  { left: 0, top: 3, color: 'lime' },
  { left: 2, top: 3, color: 'lime' },
  { left: 1, top: 4, color: 'lime' },
]
