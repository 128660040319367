import * as React from 'react'

import { Cell } from './Cell'

const range = (length: number) => new Array(length).fill(0)

export const Grid = ({
  cellSize,
  numberOfRows,
  numberOfColumns,
  backgroundColor,
}: {
  numberOfColumns: number
  numberOfRows: number
  backgroundColor: string
  cellSize: number
}): JSX.Element => {
  const rows = range(numberOfRows).map((n, row) => (
    <div key={row} style={{ fontSize: 0 }}>
      {range(numberOfColumns).map((i, col) => {
        return (
          <Cell
            className={'grid'}
            key={`${col}${row}`}
            color={backgroundColor}
            top={row}
            left={col}
            size={cellSize}
          />
        )
      })}
    </div>
  ))
  return (
    <>
      <div className="grid" style={{ whiteSpace: 'nowrap', outlineWidth: 0 }}>
        {rows}
      </div>
    </>
  )
}
