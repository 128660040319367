import * as React from 'react'
import { gameCellSize } from '../gameConfig'

export const Control = ({
  option,
  handleButtonClick,
  symbol,
}: {
  option: string
  handleButtonClick: Function
  symbol: string
}): JSX.Element => {
  const onButtonClick = () => {
    option === 'newGame' ? location.reload() : handleButtonClick(option)
  }

  return (
    <>
      <button
        className="control"
        onClick={onButtonClick}
        style={{
          fontSize: `${gameCellSize() * 1.81}px`,
          padding: 0,
          border: '0px',
          outlineWidth: 0,
          touchAction: 'manipulation',
          cursor: 'pointer',
          WebkitTapHighlightColor: 'transparent',
        }}
      >
        {symbol}
      </button>
    </>
  )
}
