import { Shape, Coordinate } from './shapes'
import { shapeWidth, shapeHeight } from './shapeUtils'

export const gridClamp = (
  shapePosition: Coordinate,
  shape: Shape,
  width: number,
  height: number,
): Coordinate => {
  let nextShapePosition = shapePosition

  return shapePosition.left < 0
    ? { left: nextShapePosition.left + 1, top: nextShapePosition.top }
    : shapePosition.left + shapeWidth(shape) > width
    ? { left: width - shapeWidth(shape), top: nextShapePosition.top }
    : shapePosition.top + shapeHeight(shape) > height
    ? { left: shapePosition.left, top: height - shapeHeight(shape) }
    : nextShapePosition
}
