import * as shapeUtils from './shapeUtils'
import { Shape, Coordinate } from './shapes'
import { shapeAdjacentCells } from './shapeUtils'

const isCellEqual = (cell: Coordinate, otherCell: Coordinate): boolean => {
  return cell.left === otherCell.left && cell.top === otherCell.top
}

export const collision = (shape1: Shape, shape2: Shape): boolean => {
  return shape2.some(shape2Cell =>
    shape1.some(shape1Cell => isCellEqual(shape2Cell, shape1Cell)),
  )
}

export const notHitPileSides = (
  shapePosition: Coordinate,
  shape: Shape,
  pile: Shape,
): boolean => {
  const positionedShape: Shape = shapeUtils.translate(shape, shapePosition)
  return !collision(positionedShape, pile)
}
export const hitPile = (
  numberOfRows: number,
  currentShape: Shape,
  shapePosition: Coordinate,
  pile: Shape,
): boolean => {
  let bottomCells = shapeUtils.getSide(
    'bottom',
    shapeUtils.translate(currentShape, shapePosition),
  )
  return Math.max(...shapeUtils.getTops(bottomCells)) === numberOfRows
    ? true
    : collision(bottomCells, shapeAdjacentCells(pile, 'top'))
    ? true
    : false
}

export const winningLines = (numberOfColumns: number, pile: Shape): Shape[] => {
  const rows = shapeUtils.shapeAsRows(pile)
  return rows.filter(row => {
    return shapeUtils.getLefts(row).length === numberOfColumns
  })
}

export const removeWinningLines = (
  pile: Shape,
  winningLines: Coordinate[][],
): Shape => {
  const winningCells = winningLines.flat()
  return pile.filter(pileCell => {
    return winningCells.filter(winningCell => {
      return (
        winningCell.left === pileCell.left && winningCell.top === pileCell.top
      )
    }).length === 1
      ? false
      : true
  })
}

export const pileDrop = (pile: Shape, winningLine: Coordinate[]): Shape => {
  const winningLineY: number = winningLine[0].top
  return pile.map(pileCell => {
    if (pileCell.top < winningLineY) {
      return {
        left: pileCell.left,
        top: pileCell.top + 1,
        color: pileCell.color,
      }
    } else return pileCell
  })
}

export const loseGame = (numberOfRows: number, pile: Shape): boolean =>
  shapeUtils.shapeHeight(pile) == numberOfRows
